import React, { useState } from "react";
import classes from "./UserInformation.module.css";
import ironImage from "../../assets/images/black.jpg";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "reactstrap"
import Papa from 'papaparse';
export default function UserInformation() {
  const [UserInfo, setUserInfo] = useState({longueur:12,maxLeft:2});
  const navigate = useNavigate();
  const handleChange = (e) => {
    setUserInfo({
      ...UserInfo,
      [e.target.name]: e.target.value,
      
    });

  };

  
  let formData = new FormData();
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: {
      "text/csv": [".csv"]
    }
  });

  const files = acceptedFiles.map((file) => (
    <li style={{ listStyle: "none", marginTop: "1rem" }} key={file.path}>
      {file?.name}
    </li>
  ));
  acceptedFiles.forEach((file) => {
    formData.append("file", file);
  });
  const somme = (array) => {
    let s = 0
    for (let i = 0; i < array.length; i++) {
      s = s + array[i]
    }
    return s
  }
  let jsonArray = {}
  var types = []
  function doStuff(metal) {
    const metalExtraction = (primaryMat, default_length) => {
      let result = [];
      let temp = []
      primaryMat && primaryMat?.map((item, index) => {
          let nbr = parseInt(item.l / default_length) * item.n;
          if (item.l % default_length > 0) {
              let restLength = item.l % default_length;
              temp.push({ l: restLength, n: item.n })
          }
  
           result = nbr !== 0 ? [...result, ...Array(parseInt(nbr)).fill(default_length)] : result;
         
      });
      return { temp, result }
  }
  const calc_metal = (obj,max_left,default_length) => {
      let material = obj.temp
      let result = obj.result
      if (material.length > 0) {
          let max_l_item = material.find(
              (item) => item.l === Math.max(...material.map((x) => x.l))
          );
          if (max_l_item) {
              let tab = [max_l_item.l]
              max_l_item.n = max_l_item.n - 1;
              if (max_l_item.n === 0) {
                  material.splice(material.indexOf(max_l_item), 1);
              }
              for (let i = 0; i < tab.length; i++) {
                  if (somme(tab) < default_length) {
                      let max_l_item2 = material.find(
                          (item) =>
                              item.l ===
                              Math.max(
                                  ...material
                                      .filter((item) => (((default_length - (item.l + somme(tab)) === 0)) || (default_length - (item.l + somme(tab)) >= max_left)))
                                      .map((item) => item.l)
                              )
                      );
                      if (max_l_item2) {
                          tab.push(max_l_item2.l)
                          max_l_item2.n = max_l_item2.n - 1;
                          if (max_l_item2.n === 0) {
                              material.splice(material.indexOf(max_l_item2), 1);
                          }
                      }
                  }
              }
              result.push(tab)
          }
          calc_metal({ temp: material, result: result },max_left,default_length);
      }
  
      return {
          nbr_de_barre: result.length,
          decoupage: result,
      };
  };
  
  
  
  let finalRes = {}
   Object.keys(jsonArray).forEach(function (key, index) {
      finalRes[key]= calc_metal(metalExtraction(metal[key],  UserInfo?.longueur),UserInfo?.maxLeft,UserInfo?.longueur);
  });

    navigate("/Recommendation", { state: { finalRes, UserInfo } })
  }
  const abc = (callBack) => {
    acceptedFiles.length > 0 && Papa.parse(acceptedFiles[0], {
      download: true,
      complete: function (data) {
        data.data.shift()
        for (let i = 0; i < data.data.length; i++) {
          if (types.includes(data.data[i][2])) {

          } else {
            data.data[i][2] && types.push(data.data[i][2])
          }
        }
        // eslint-disable-next-line
        types.map((elem) => {
          jsonArray = { ...jsonArray, [elem]: [] }
          data.data.map((i) => {
            if (i[2] === elem) {
              jsonArray[elem].push({ l: parseInt(i[0]), n: parseInt(i[1]) })
            }
          })

        })
        callBack(jsonArray);

      }
    });
  }
  return (
    <div className={classes.container}>
      <img alt="" className={classes.ironImage} src={ironImage}></img>

      <div className={classes.formHolder}>
        <form className={classes.form}>

          <div
            style={{
              border: "3px black dashed",

              cursor: "pointer",
            }}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <div>
              <Row>
                <Col style={{ display: "flex", justifyContent: "center" }}>
                  <FontAwesomeIcon style={{ height: "2.5rem", paddingTop: "0.5rem" }} icon={faUpload} />
                </Col>
                <Col>
                  <p className={classes.selectFile}>
                    Glisser-déposer votre fichier ici, ou bien cliquez pour
                    sélectionner des fichiers
                  </p></Col>
              </Row>
            </div>
            <aside>
              <ul>{files}</ul>
            </aside>
          </div>
          {/* <button className={classes.btn} onClick={selectFile}>
        ajouter fichier
      </button> */}
          <Row style={{ display: "flex", flexDirection: "column" }}>
            <Col style={{ display: "flex", flexDirection: "column", marginBlock: '1rem ', alignItems: "center" }}>
              <label className={classes.label} htmlFor="longueur">
                Longueur
              </label>
              <input
                className={classes.input}
                type="text"
                name="longueur"
                id="longueur"
                onChange={handleChange}
              ></input>
            </Col>
            <Col style={{ display: "flex", flexDirection: "column", marginBlock: '1rem ', alignItems: "center" }}>
              <label className={classes.label} htmlFor="maxLeft">
                Longueur de barre restante
              </label>
              <input
                className={classes.input}
                type="text"
                name="maxLeft"
                id="maxLeft"
                onChange={handleChange}
              ></input>
            </Col>
            <Col style={{ textAlign: "center" }}>
              <button
                type="button"
                className={classes.calculateBtn}
                onClick={() => {
                     if (acceptedFiles.length > 0 && UserInfo.longueur !=="" && UserInfo.maxLeft !=="") {
                    abc(doStuff)
                  }
                }}
              >
                Calculer
              </button></Col></Row>
        </form>
      </div>
      
    </div>
  );
}
