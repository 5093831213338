import React from "react";
import { useLocation } from "react-router-dom";
import classes from "./recommendation.module.css";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
export default function Recommendation() {
  const params = useLocation();
  const { finalRes, UserInfo } = params.state;
  const navigate = useNavigate();
  let nbr=0
  
  function countOccurences(tab){

    var result = {};
    tab.forEach(function(elem){
    
       if(elem in result){
        result[elem] = ++result[elem];
      }
      else{
        result[elem] = 1;
      }
    });
    return result;
  }
  // eslint-disable-next-line
  {Object.keys(finalRes).map(function (key, index) { 
    // eslint-disable-next-line
  Object.keys(countOccurences(finalRes[key].decoupage)).map((k,index) => {
    nbr++
  })
 })}

 
  return (

<div>
    <div className={classes.container}>
      {/* <img className={classes.ironImage} src={ironImage}></img> */}
      <div className={classes.subcontainer}>
        <form className={classes.infoBox}>
          <div className={classes.info}>
           {UserInfo &&  <h2 className={classes.GeneralInfo} htmlFor="username">
            Barres métalliques de longueur {UserInfo?.longueur} cm avec maximum de déchets {UserInfo?.maxLeft} cm
            </h2>}    {Object.keys(finalRes).map(function (key, i) {
              return(
              <table className={classes.table} key={i}>
               
              <thead>
                <tr>
                  <th colSpan={3}><h3>La distribution des barres du type {key}</h3></th>
                
                </tr>
                </thead>
              <tbody>
                <tr>
               
                <td ><h4> Nombre de barres </h4></td>
                <td> <h4>Découpage </h4></td>
              </tr>
                
                  <tr >
                    <td  rowSpan={nbr+1} >{finalRes[key]?.nbr_de_barre}</td></tr>
                      {Object.keys(countOccurences(finalRes[key]?.decoupage)).map((k,index)=>
                        {
                          
                   return(
                    <tr key={index}>
                         <td key={index} >
                          <div className={classes.title}>
                            <p>
                        {countOccurences(finalRes[key]?.decoupage)[k]===1?countOccurences(finalRes[key]?.decoupage)[k]+" piéce" :countOccurences(finalRes[key]?.decoupage)[k]+" piéces"}  de {k} cm
                        </p>
                        </div>
                          </td>
                    </tr>
                   )
                     })}
                </tbody>
            </table>) })}
          </div>
        </form>
        <Button className={classes.RetourButton} onClick={() => navigate("/")}>Retour</Button>
      </div>
     
    </div>
    </div>
  );
}
