import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import UserInformation from "./pages/UserInformation/UserInformation";
import Recommendation from "./pages/recommendation/Recommendation";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<UserInformation />} />
        <Route path="/Recommendation" element={<Recommendation />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
